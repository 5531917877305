import { Fragment, useReducer } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const MenuCart = () => {
	let { pathname } = useLocation();

	const [cart, setCart] = useState([]);
	const [localCart, setLocalCart] = useState([]);
	const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

	let cartTotalPrice = 0;

	//***authentication code***/
	const customerID = localStorage.getItem("restaurants-customerID");

	//*handling delete items when changes
	const [itemMainCartEvent, setItemMainCartEvent] = useState(0);

	const handleMainCartEvent = () => {
		setItemMainCartEvent(itemMainCartEvent + 1);
	};

	//* get cart data
	useEffect(() => {
		if (customerID) {
			axios
				.get(`${process.env.REACT_APP_FOS_CLIENT_API}/cartdata`, {
					headers: { Authorization: customerID },
				})
				.then((res) => setCart(res.data))
				.catch((err) => console.log(err));
		}
	}, [reducerValue]);

	//* delete cart data

	const handleDelete = (id) => {
		axios
			.delete(`${process.env.REACT_APP_FOS_CLIENT_API}/deletecartitem/${id}`)
			.then((res) => console.log(res.data))
			.catch((err) => console.log(err));
		forceUpdate();
	};

	//~Local Cart

	//* get local cart data

	useEffect(() => {
		const LocalcartData = JSON.parse(localStorage.getItem("localCart"));
		if (customerID != null) {
			localStorage.removeItem("localCart");
		} else {
			if (LocalcartData) {
				setLocalCart(LocalcartData);
			}
		}
	}, [customerID]);

	//*set itemDeleteEvent
	const [itemDeleteEvent, setItemDeleteEvent] = useState(0);
	const handleItemDelete = () => {
		setItemDeleteEvent(itemDeleteEvent + 1);
	};

	//*handle local cart delete event

	const handleLocalCartDelete = (itemId) => {
		handleItemDelete(); // Update the main cart
		const updatedLocalCart = localCart.filter((item) => item.cartid !== itemId);
		console.log(updatedLocalCart);
		setLocalCart(updatedLocalCart);
		localStorage.setItem("localCart", JSON.stringify(updatedLocalCart));
		handleMainCartEvent(); // Trigger a re-render
	};

	return (
		<div className="shopping-cart-content">
			{cart && cart.length > 0 ? (
				<Fragment>
					<ul>
						{cart.map((item, index) => {
							cartTotalPrice += item.price * item.count;
							return (
								<li className="single-shopping-cart" key={index}>
									<div className="shopping-cart-img">
										<img
											className="img-fluid"
											src={"http://localhost:5555/images/" + item.image}
											alt="img"
										/>
									</div>
									<div className="shopping-cart-title">
										<h4>{item.menuitem}</h4>
										<h6>Qty: {item.count}</h6>
										<h6>Subtotal: $  {item.price * item.count}</h6>
									</div>
									<div className="shopping-cart-delete">
										<button
											onClick={() => {
												handleDelete(item.cartid);
												handleLocalCartDelete(item.cartid);
											}}
										>
											<i className="fa fa-times-circle" />
										</button>
									</div>
								</li>
							);
						})}
					</ul>
					<div className="shopping-cart-total">
						<h4>
							Total : <span className="shop-total">$  {cartTotalPrice}</span>
						</h4>
					</div>
					<div className="shopping-cart-btn btn-hover text-center">
						<Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
							view cart
						</Link>
						<Link
							className="default-btn"
							to={process.env.PUBLIC_URL + "/checkout"}
						>
							checkout
						</Link>
					</div>
				</Fragment>
			) : (
				<p className="text-center">No items added to cart</p>
			)}
		</div>
	);
};

export default MenuCart;
