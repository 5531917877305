import React, { useEffect, useState } from "react";
import { Button, List, Modal, Popover } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import axios from "axios";

function Topping({ menuItem, handleAdd, handleClose }) {
  const [quantity, setQuantity] = useState("1");
  const [selectedBase, setSelectedBase] = useState(null);
  const [menuToppings, setMenuToppings] = useState([]);
  const [menuBases, setMenuBases] = useState([]);
  const [selectedToppings, setSelectedToppings] = useState([]);

  console.log(menuItem, "hlo");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FOS_CLIENT_API}/getdesiredtoppingdata/` +
          menuItem.menuitemsid
      )
      .then((res) => {
        setMenuToppings(res.data);
      })
      .catch((err) => console.log(err));
  }, [menuItem]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_FOS_CLIENT_API}/getdesiredbasedata/` +
          menuItem.menuitemsid
      )
      .then((res) => {
        setMenuBases(res.data);
      })
      .catch((err) => console.log(err));
  }, [menuItem]);

  const calculateToppingsSummary = () => {
    const selectedToppingNames = menuToppings
      .filter((topping) => selectedToppings.includes(topping.toppingPriceId))
      .map((topping) => topping.toppingName);
    return selectedToppingNames.join(", ");
  };

  const calculateBaseSummary = () => {
    const selectedBaseName = menuBases.find(
      (base) => base.baseId === selectedBase
    )?.baseName;
    return selectedBaseName || "None";
  };

  const data = [
    { key: "Price", value: `${menuItem.price}` },
    { key: "Quantity", value: `${quantity}` },
    { key: "Base", value: calculateBaseSummary() },
    { key: "Toppings", value: calculateToppingsSummary() },
  ];

  const toppingsPrices = menuToppings.reduce((acc, topping) => {
    acc[topping.toppingPriceId] = topping.toppingPrice;
    return acc;
  }, {});

  const basesPrices = menuBases.reduce((acc, base) => {
    acc[base.baseId] = base.basePrice;
    return acc;
  }, {});

  const calculateTotal = () => {
    let total = menuItem.price * parseInt(quantity, 10);
  
    if (selectedBase) {
      total += parseFloat(basesPrices[selectedBase]) * parseInt(quantity, 10);
    }
  
    selectedToppings.forEach((toppingPriceId) => {
      total += parseFloat(toppingsPrices[toppingPriceId]) * parseInt(quantity, 10);
    });
  
    return total.toFixed(2);
  };
  
  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleBaseChange = (e) => {
    setSelectedBase(e.target.value);
  };

  const handleToppingChange = (toppingPriceId) => {
    setSelectedToppings((prevSelectedToppings) =>
      prevSelectedToppings.includes(toppingPriceId)
        ? prevSelectedToppings.filter((id) => id !== toppingPriceId)
        : [...prevSelectedToppings, toppingPriceId]
    );
  };

  const handleAddToCart = () => {
    const baseId = selectedBase
      ? menuBases.find((base) => base.baseId === selectedBase).basePriceId
      : null;

    const toppingsId = selectedToppings.join(",");

    handleAdd(
      menuItem,
      toppingsId,
      baseId,
      calculateTotal(),
      quantity,
      menuToppings.filter((topping) =>
        selectedToppings.includes(topping.toppingPriceId)
      )
    );
    handleClose();
  };

  return (
    <div>
      <Modal
        title="Veg Pizza"
        visible={true}
        footer={null}
        style={{ marginTop: "-50px" }}
        width={1000}
        onCancel={handleClose}
      >
        <div className="row p-3">
          <div className="col-md-8 border overflow-auto container-custom-scrollbar">
            <div>
              <div className="w-100 row">
                <div className="col-lg-6">
                  <div className="px-3 mt-md-3">
                    <h5 style={{ margin: "0" }}>Quantity</h5>
                    <div>
                      <label className="form-label"></label>
                      <div>
                        <select
                          className="form-select"
                          value={quantity}
                          onChange={handleQuantityChange}
                        >
                          {["1", "2", "3", "4", "5"].map((qty) => (
                            <option key={qty} value={qty}>
                              {qty}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="px-3 mt-md-4">
                    {menuBases.length > 0 && (
                      <>
                        <h5>Base</h5>
                        <div>
                          <div>
                            <select
                              className="form-select"
                              value={selectedBase || ""}
                              onChange={handleBaseChange}
                            >
                              <option value="">
                                Select base
                              </option>
                              {menuBases.map((base) => (
                                <option key={base.baseId} value={base.baseId}>
                                  {base.baseName} (${base.basePrice})
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mt-2 d-flex align-item-center justify-content-center w-90 flex-column">
                  <h5>Preview</h5>
                  <img
                    className="rounded img-fluid mt-3 "
                    src={
                      `${process.env.REACT_APP_FOS_CLIENT_API}/images/` +
                      menuItem.image
                    }
                  ></img>
                </div>
              </div>

              {menuToppings.length > 0 && (
                <div className="container border p-3 mt-3 rounded mb-2">
                  <div className="mt-3">
                    <h5>Add {menuToppings[0].type}</h5>
                    <div className="row mt-3">
                      {menuToppings.map((topping) => (
                        <div key={topping.toppingPriceId} className="col-lg-3">
                          <div className="mb-3 form-check">
                            <label
                              name="checkbox"
                              className="form-label cursor-pointer"
                              onClick={() =>
                                handleToppingChange(topping.toppingPriceId)
                              }
                            >
                              {topping.toppingName}{" "}
                              <div>(${topping.toppingPrice})</div>
                            </label>
                            <input
                              name="checkboxs"
                              type="checkbox"
                              style={{
                                borderColor: "#A749FF", // Border color when unchecked
                                backgroundColor: selectedToppings.includes(
                                  topping.toppingPriceId
                                )
                                  ? "#A749FF"
                                  : "transparent", // Background color when checked
                              }}
                              className="form-check-input"
                              checked={selectedToppings.includes(
                                topping.toppingPriceId
                              )}
                              onChange={() =>
                                handleToppingChange(topping.toppingPriceId)
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="mt-2">
              <h4>Price Summary</h4>
              <List
                className="mt-4"
                bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    <strong>{item.key}</strong>: {item.value}
                  </List.Item>
                )}
              />
              <div className="text-end w-100 mt-2">
                Total Amount: <strong>${calculateTotal()}</strong>
              </div>
            </div>
            <div className="mt-6">
              <button
                type="primary"
                onClick={handleAddToCart}
                className="container btn mt-4"
                style={{ backgroundColor: "#A749FF", color: "#FFFFFF" }}
              >
                <ShoppingCartOutlined /> Add To Cart
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Topping;
