import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
    ExclamationCircleOutlined,
    LoadingOutlined,
    PayCircleOutlined,
    SafetyOutlined,
    SmileOutlined,
    SolutionOutlined,
    UserOutlined,
  } from "@ant-design/icons";
  import { Steps } from "antd";

function S_Address() {

  return (
    <div className='bg-light p-2 mb-4 border' >
      {/* <div className="d-flex">
            <h4 style={{ color: "blue" }}>Account</h4>
            <div className="mx-2 d-flex align-items-center">
                <FontAwesomeIcon
                    style={{
                        color: "blue",
                        backgroundColor: "blue",
                        borderRadius: "50%",
                    }}
                    icon={faCircle}
                />
                <div
                    style={{
                        height: "3px",
                        width: "150px",
                        background: "blue",
                    }}
                ></div>
            </div>
            <h4 style={{ color: "blue" }}>Address</h4>
            <div className="mx-2 d-flex align-items-center">
                <FontAwesomeIcon
                    style={{
                        color: "blue",
                        borderRadius: "50%",
                    }}
                    icon={faCircle}
                />
                <div
                    style={{
                        height: "3px",
                        width: "150px",
                        background: "gray",
                    }}
                ></div>
                <FontAwesomeIcon
                    style={{ color: "gray" }}
                    icon={faCircle}
                />
            </div>
            <h4 style={{ color: "gray" }}>Payment</h4>
        </div> */}
      <Steps>
        <Steps.Step
          title="Account"
          status="finish"
          style={{ color: "green" }}
          description={<span className="text-success">success <SafetyOutlined /></span>}
          
          icon={<UserOutlined style={{color:'#A749FF'}} />}
        />
        <Steps.Step
          title="Address"
          status="process"
          icon={<SolutionOutlined style={{color:'#A749FF'}} />}
          description={<span className="text-secondary">progress <LoadingOutlined/></span>}
        />
        <Steps.Step
          title="Payment"
          status="process"
          icon={<PayCircleOutlined style={{color:'gray'}} />}
          description={<span className="text-secondary">pending <ExclamationCircleOutlined /></span>}
          
        />
      </Steps>
    </div>
  );
}

export default S_Address;
