import { Fragment, useState } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import MenuList from "./MenuList";
import HomeCart from "./HomeCart";

const Home = () => {
	const [itemAddEvent, setItemAddEvent] = useState(0);
	const [itemDeleteEvent, setItemDeleteEvent] = useState(0);

	//* handling items when add
	const handleItemAdd = () => {
		setItemAddEvent(itemAddEvent + 1);
	};
	//* handling items when delete
	const handleItemDelete = () => {
		setItemDeleteEvent(itemDeleteEvent + 1);
	};

	//* handling menuitem adding confirmation status
	const [confirmationAddStatus, setConfirmationAddStatus] = useState(false);
	const handleConfirmationAddStatus = () => {
		setConfirmationAddStatus(true);
		setTimeout(() => {
			setConfirmationAddStatus(false);
		}, 2000);
	};

	//* handling cart item remove status
	const [confirmationRemoveStatus, setConfirmationRemoveStatus] =
		useState(false);
	const handleConfirmationRemoveStatus = () => {
		setConfirmationRemoveStatus(true);
		setTimeout(() => {
			setConfirmationRemoveStatus(false);
		}, 2000);
	};

	return (
		<Fragment>
			<SEO titleTemplate="Home" description="Home Page" />
			<LayoutOne
				itemAddEvent={itemAddEvent}
				itemDeleteEvent={itemDeleteEvent}
				headerTop="visible"
			>
				{/* handling menuitem adding confirmation status */}

				{confirmationAddStatus ? (
					<p
						className="p-2"
						style={{
							color: "white",
							position: "absolute",
							right: "11%",
							background: "#a749ff",
						}}
					>
						Successfully added 🥳
					</p>
				) : (
					""
				)}

				{/* handling cart item remove status */}
				{confirmationRemoveStatus ? (
					<p
						className="p-2"
						style={{
							color: "white",
							position: "fixed",
							right: "11%",
							background: "crimson",
						}}
					>
						Successfully Removed 🫤
					</p>
				) : (
					""
				)}

				<div className="checkout-area pt-50 pb-100">
					<div className="container">
						<div className="row">
							{/* ------MENU ITEMS------ */}
							<div
								className="col-lg-8"
								style={{ maxHeight: "70vh", overflowY: "scroll" }}
							>
								<div className="myaccount-wrapper">
									<MenuList
										handleConfirmationAddStatus={handleConfirmationAddStatus}
										onItemAdd={handleItemAdd}
										itemDeleteEvent={itemDeleteEvent}
									/>
								</div>
							</div>
							<div className="col-lg-4 cart-wrap same-style ">
								<HomeCart
									handleConfirmationRemoveStatus={
										handleConfirmationRemoveStatus
									}
									checkoutButtonVisible={true}
									itemAddEvent={itemAddEvent}
									handleItemDelete={handleItemDelete}
								/>
							</div>
						</div>
					</div>
				</div>
			</LayoutOne>
		</Fragment>
	);
};

export default Home;
