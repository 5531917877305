import React from "react";
import { ThreeCircles } from "react-loader-spinner";

function Loader({ color, height, width }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
			}}
		>
			<ThreeCircles
				height={height}
				width={width}
				color={color}
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
				ariaLabel="three-circles-rotating"
				outerCircleColor=""
				innerCircleColor=""
				middleCircleColor=""
			/>
		</div>
	);
}

export default Loader;
