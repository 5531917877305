import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Swal from "sweetalert2";
import Loader from "../../components/Loaders/Loader";
import Topping from "./Topping";

function MenuList({ 
  onItemAdd,
  itemAddEvent,
  handleConfirmationAddStatus,
  itemDeleteEvent,
}) {
  const [menu, setMenu] = useState([]);
  const [menuitem, setMenuItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [localCart, setLocalCart] = useState([]);
  const [addTopping, setAddTopping] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const customerID = localStorage.getItem("restaurants-customerID");

  console.log(menuitem);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/getmenudata`, {
        headers: { Authorization: customerID },
      })
      .then((res) => setMenu(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/getmenuitemsdata`, {
        headers: { Authorization: customerID },
      })
      .then((res) => {
        setMenuItem(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  //* get cart data
  useEffect(() => {
    if (customerID) {
      axios
        .get(`${process.env.REACT_APP_FOS_CLIENT_API}/cartdata`, {
          headers: { Authorization: customerID },
        })
        .then((res) => setCart(res.data))
        .catch((err) => console.log(err));
    }
  }, [customerID, itemDeleteEvent, itemAddEvent, refresh]);

  //* handle refresh the local cart
  useEffect(() => {
    const currentLocalCart =
      JSON.parse(localStorage.getItem("localCart")) || [];
    setLocalCart(currentLocalCart);
  }, [itemDeleteEvent, itemAddEvent]);

  //* handle add event
  function handleAdd(
    item,
    toppingsId,
    baseId,
    subTotal,
    quantityCount,
    selectedToppingsInfo
  ) {
    console.log("Attempting to add item:");
    console.log("Item:", item);
    console.log("Toppings ID:", toppingsId);
    console.log("Base ID:", baseId);
    console.log("Customer ID:", customerID);
  
    // Normalize the IDs for comparison
    const normToppingsId = toppingsId || "";
    const normBaseId = baseId || null;
  
    //~ if item already exists in cart
    const itemExistsInCart = cart.some(
      (k) =>
        k.menuitemid === item.menuitemsid &&
        k.toppingsid === normToppingsId &&
        k.baseid === normBaseId
    );
  
    const itemExistsInLocalCart = localCart.some(
      (k) =>
        k.menuitemid === item.menuitemsid &&
        k.toppingsId === normToppingsId &&
        k.baseId === normBaseId
    );
  
    console.log("itemExistsInCart:", itemExistsInCart);
    console.log("itemExistsInLocalCart:", itemExistsInLocalCart);
  
    if (itemExistsInCart || itemExistsInLocalCart) {
      let timerInterval;
      Swal.fire({
        title: "Please Check cart",
        text: `"${item.menuitem}" is already in cart`,
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer()?.querySelector("b"); // Use optional chaining
          if (b) {
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          }
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
        }
      });
      return;
    }
  
    //~ if adding new item to cart
    if (customerID != null) {
      axios
        .post(
          `${process.env.REACT_APP_FOS_CLIENT_API}/cart`,
          {
            menuid: item.menuid,
            menuitemid: item.menuitemsid,
            menuname: item.menuitem,
            price: item.price,
            quantity: item.quantity,
            count: quantityCount,
            image: item.image,
            cid: customerID,
            toppingsId: normToppingsId,
            baseId: normBaseId,
            subTotal,
          },
          { headers: { Authorization: customerID } }
        )
        .then((res) => {
          console.log("Item added to cart successfully.");
          onItemAdd();
          setRefresh(!refresh);
          handleConfirmationAddStatus();
        })
        .catch((err) => console.log(err));
    } else {
      // Retrieve the current local cart data from localStorage
      const currentLocalCart =
        JSON.parse(localStorage.getItem("localCart")) || [];
  
      // Check if the item already exists in the local cart
      const existingItem = currentLocalCart.find(
        (k) =>
          k.menuitemid === item.menuitemsid &&
          k.toppingsId === normToppingsId &&
          k.baseId === normBaseId
      );
  
      if (existingItem) {
        let timerInterval;
        Swal.fire({
          title: "Please Check cart",
          text: `"${item.menuitem}" is already in cart`,
          timer: 4000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer()?.querySelector("b"); // Use optional chaining
            if (b) {
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            }
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else {
        // If the item doesn't exist in the local cart, add it to the current local cart data
        let singlePrice = subTotal / quantityCount;
        console.log("local single price -> ", singlePrice);
        const updatedLocalCart = [
          ...currentLocalCart,
          {
            cartid: currentLocalCart.length + 1,
            menuid: item.menuid,
            menuitemid: item.menuitemsid,
            menuname: item.menuitem,
            price: item.price,
            quantity: item.quantity,
            count: quantityCount,
            image: item.image,
            cid: customerID,
            toppingsId: normToppingsId,
            baseId: normBaseId,
            subtotal: subTotal,
            semiTotal: singlePrice,
            toppings: selectedToppingsInfo,
          },
        ];
        // Update the local cart data in localStorage
        localStorage.setItem("localCart", JSON.stringify(updatedLocalCart));
  
        console.log(
          "local cart -> " + JSON.parse(localStorage.getItem("localCart"))
        );
  
        // Trigger any necessary UI updates
        onItemAdd();
        handleConfirmationAddStatus();
  
        // Update the local state variable if needed (optional)
        setLocalCart(updatedLocalCart);
      }
    }
  }
  
  

  const handleAddTopping = (item) => {
    setSelectedMenuItem(item);
    setAddTopping(true);
  };

  const handleClose = () => {
    setAddTopping(false);
  };

  return loading ? (
    <Loader color="#000000" height="300px" />
  ) : (
    <div>
      {menu?.map((m, index) => (
        <Accordion className="py-2 px-2" key={index} defaultActiveKey={[]}>
          <Accordion.Item>
            <Accordion.Header>{m.menuname}</Accordion.Header>
            {menuitem?.map((n, index) =>
              m.menuid == n.menuid ? (
                <Accordion.Body key={index}>
                  <div className="row">
                    <div className="col-3">
                      <img
                        style={{
                          height: "3.5rem",
                          borderRadius: "50%",
                          width: "3.5rem",
                        }}
                        src={
                          `${process.env.REACT_APP_FOS_CLIENT_API}/images/` +
                          n.image
                        }
                        alt=""
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <b>{n.menuitem}</b>
                    </div>
                    <div className="col-3 mt-2">
                      <b>₹ {n.price}</b>
                    </div>
                    <div className="col-3 mt-2">
                      {n.stock === "false" ? (
                        <button
                     style={{backgroundColor:'red',color:'white'}}
                          className="btn btn-sm btt py-2"
                          disabled
                          
                        >
                          {n.stock === "false" ? "Unavailable" : "Coming Soon"}
                        </button>
                      ) : (
                        <button
                          style={{ color: "white", background: "#a749ff" }}
                          className="btn"
                          onClick={() => handleAddTopping(n)}
                        >
                          ADD
                        </button>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              ) : (
                ""
              )
            )}
          </Accordion.Item>
        </Accordion>
      ))}
      {addTopping ? (
        <Topping
          menuItem={selectedMenuItem}
          handleAdd={handleAdd}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MenuList;
