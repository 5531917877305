import React, { Fragment, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ViewOrder from "./ViewOrder";
import { CloseOutlined } from "@ant-design/icons";

const MyOrders = () => {
  let { pathname } = useLocation();
  const [totalOrders, setTotalOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const customerID = localStorage.getItem("restaurants-customerID");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_FOS_CLIENT_API}/getinvoicedetailsclient`, {
        headers: { Authorization: customerID },
      })
      .then((res) => setTotalOrders(res.data));
  }, [reducerValue]);

  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalOrders.length / itemsPerPage);

  const paginatedOrders = totalOrders.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Fragment>
      <SEO
        titleTemplate="My Orders"
        description="My Orders page of flone react minimalist eCommerce template."
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "myorders", path: process.env.PUBLIC_URL + pathname },
          ]}
        />
        <div style={{height:'100vh',overflowX:'auto'}} className="mx-md-4 px-md-4">
          <table className="table mx-5">
            <thead>
              <tr>
                <th>#</th>
                <th>Invoice Number</th>
                <th>Order status</th>
                <th>Total Bill</th>
                <th>Date & Time</th>
                <th>Order</th>
              </tr>
            </thead>
            <tbody>
              {paginatedOrders && paginatedOrders.length > 0 ? (
                paginatedOrders.map((val, index) => (
                  <tr key={val.invoiceNumber}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{val.invoiceNumber}</td>
                    <td>{val.orderstatus}</td>
                    <td>$ {val.totalBill}</td>
                    <td>
                      {new Date(val.invoiceDate).toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedOrderId(val.invoiceNumber);
                        }}
                        className="btn btn-sm"
                        style={{
                          backgroundColor: "rgb(167, 73, 255)",
                          color: "white",
                        }}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Orders</td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className="pagination"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`border mx-1 btn btn-sm ${
                  currentPage === index + 1 ? "btn-light" : "btn-primary"
                }`}
                style={{ cursor: "pointer" }}
              >
                {index + 1}
              </button>
            ))}
          </div>

          {/* Modal */}
          {selectedOrderId != null && (
            <div
              className="modal-overlay"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent gray background
                zIndex: 999,
              }}
            >
              <div
                className="modal-content"
                style={{
                  position: "absolute",
                  top: "5%",
                  left: "0%",
                  right: "5%",
                  bottom: "5%",
                  background: "white",
                  overflowY: "auto",
                  padding: "20px",
               
                }}
              >
                <div
                  onClick={() => setSelectedOrderId(null)}
                  style={{
                    position: "absolute",
                    top: "3%",
                    right: "3%",
                    cursor: "pointer",
                  }}
                >
                  <CloseOutlined style={{ fontSize: "20px" }} />
                </div>
                <ViewOrder id={selectedOrderId} />
              </div>
            </div>
          )}
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default MyOrders;
