import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";

function AddressUpdate({ addId, handleAddressUpdateEvent }) {
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [fullAddress, setFullAddress] = useState("");
	const [pincode, setPincode] = useState("");
	//* get address
	const customerID = localStorage.getItem("restaurants-customerID");
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/getaddress/` + addId, {
				headers: { Authorization: customerID },
			})
			.then((res) => {
				setName(res.data.customername);
				setPhoneNumber(res.data.phnum);
				setFullAddress(res.data.address);
				setPincode(res.data.pincode);
			})
			.catch((err) => console.log(err));
	}, [addId]);

	//* handle address update

	const handleUpdate = (e) => {
		e.preventDefault();
		axios
			.put(
				`${process.env.REACT_APP_FOS_CLIENT_API}/editaddress/` + addId,
				{
					name,
					phoneNumber,
					fullAddress,
					pincode,
				},
				{ headers: { Authorization: customerID } },
			)
			.then((res) => {
				Swal.fire({
					position: "top-end",
					icon: "success",
					title: "Address Updated",
					showConfirmButton: false,
					timer: 1500,
				});
				handleAddressUpdateEvent();
			})
			.catch((err) => alert(err));
	};

	return (
		<form onSubmit={handleUpdate} className="col-lg-11">
			<div className="billing-info-wrap">
				<h5 className="text-primary mb-4">
					UPDATE ADDRESS
					<FontAwesomeIcon
						className="ms-2 "
						style={{ cursor: "pointer", color: "blue", fontSize: "1.4rem" }}
						icon={faPenToSquare}
					/>
				</h5>
				<div className="row">
					<div className="col-lg-6 col-md-6">
						<div className="billing-info mb-20">
							<label>First Name</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-5">
						<div className="billing-info mb-20">
							<label>PhoneNumber</label>
							<input
								pattern="[0-9]*"
								type="tel"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								required
							/>
						</div>
					</div>

					<div className="col-lg-6">
						<div className="billing-info mb-20">
							<label>Address</label>
							<textarea
								value={fullAddress}
								rows={3}
								cols={3}
								className="billing-address"
								type="text"
								onChange={(e) => setFullAddress(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="col-lg-5">
						<div className="billing-info mb-20">
							<label>Postcode / ZIP</label>
							<input
								pattern="[0-9]*"
								value={pincode}
								type="text"
								onChange={(e) => setPincode(e.target.value)}
								required
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="pb-3 d-flex justify-content-center gap-3">
				<button
					onClick={() => handleAddressUpdateEvent(0)}
					style={{ background: "rgb(167, 73, 255)" }}
					className="my-3 btn btn-primary"
				>
					Back
				</button>
				<button
					style={{ background: "rgb(167, 73, 255)" }}
					className="my-3 btn btn-primary"
				>
					Update Address
				</button>
			</div>
		</form>
	);
}

export default AddressUpdate;
