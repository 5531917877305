import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function NewAddress({ forceUpdate, setNewAddress }) {
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [fullAddress, setFullAddress] = useState("");
	const [pincode, setPincode] = useState("");

	//***authentication code***/
	const customerID = localStorage.getItem("restaurants-customerID");

	const [address, setAddress] = useState([]);

	const [phoneError, setPhoneError] = useState("");
	const [pincodeError, setPincodeError] = useState("");

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/getsingleuser`, {
				headers: { Authorization: customerID },
			})
			.then((res) => {
				setName(res.data[0].customername);
				setPhoneNumber(res.data[0].phnum);
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/customerexistingaddress`, {
				headers: { Authorization: customerID },
			})
			.then((res) => setAddress(res.data))
			.catch((err) => console.log(err));
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		
		// Validation
		const phoneRegex = /^\d{10}$/;
		const pincodeRegex = /^\d{6}$/;

		let valid = true;

		if (!phoneRegex.test(phoneNumber)) {
			setPhoneError("Phone number must be exactly 10 digits.");
			valid = false;
		} else {
			setPhoneError("");
		}

		if (!pincodeRegex.test(pincode)) {
			setPincodeError("Pincode must be a valid number.");
			valid = false;
		} else {
			setPincodeError("");
		}

		if (!valid) return;

		console.log("submitted");
		axios
			.post(
				`${process.env.REACT_APP_FOS_CLIENT_API}/customeraddress`,
				{
					customerID,
					name,
					phoneNumber,
					fullAddress,
					pincode,
				},
				{ headers: { Authorization: customerID } },
			)
			.then((res) => {
				console.log("Added");
				setNewAddress(false);
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
				forceUpdate();
			})
			.catch((err) => alert(err));
	};

	return (
    <form onSubmit={handleSubmit} className="col-lg-11">
      <div className="billing-info-wrap">
        <h3>New Address</h3>
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="billing-info mb-20">
              <label>Full Name *</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="billing-info mb-20">
              <label>Phone Number *</label>
              <input
                pattern="\d*"
                maxLength="10"
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              {phoneError && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {phoneError}
                </span>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="billing-info mb-20">
              <label>Address *</label>
              <textarea
                rows={3}
                cols={3}
                className="billing-address"
                type="text"
                onChange={(e) => setFullAddress(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="billing-info mb-20">
              <label>Postcode / ZIP *</label>
              <input
                pattern="\d*"
                type="text"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
              {pincodeError && (
                <span className="text-danger" style={{ fontSize: "12px" }}>
                  {pincodeError}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {address.length > 0 ? (
        <Link
          style={{ color: "rgb(155, 0, 255)" }}
          onClick={() => {
            setNewAddress(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          - Use Existing Address
        </Link>
      ) : (
        ""
      )}
      <div className="pb-3 d-flex justify-content-center gap-3">
        {address.length > 0 ? (
          <p
            onClick={() => {
              setNewAddress(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            style={{ background: "rgb(167, 73, 255)" }}
            className="my-3 btn btn-primary"
          >
            Back
          </p>
        ) : (
          <Link
            onClick={() => localStorage.setItem("deliveryMode", "")}
            to={"/checkout"}
          >
            <p
              style={{ background: "rgb(167, 73, 255)" }}
              className="my-3 btn btn-primary"
            >
              Back
            </p>
          </Link>
        )}
        <button
          style={{ background: "rgb(167, 73, 255)" }}
          className="my-3 btn btn-primary"
        >
          Confirm Address
        </button>
      </div>
    </form>
  );
}

export default NewAddress;
