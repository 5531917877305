import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";

const IconGroup = ({
  iconWhiteClass,
  itemAddEvent,
  itemDeleteEvent,
  itemMainCartEvent,
}) => {
  const navigate = useNavigate();
  const customerID = localStorage.getItem("restaurants-customerID");

  // Manage cart state
  const [cart, setCart] = useState([]);
  useEffect(() => {
    if (customerID) {
      axios
        .get(`${process.env.REACT_APP_FOS_CLIENT_API}/cartdata`, {
          headers: { Authorization: customerID },
        })
        .then((res) => setCart(res.data))
        .catch((err) => console.log(err));
    }
  }, [customerID, itemAddEvent, itemDeleteEvent, itemMainCartEvent]);

  // Manage local cart state
  const [localCart, setLocalCart] = useState([]);
  useEffect(() => {
    const localCartData = JSON.parse(localStorage.getItem("localCart"));
    if (customerID) {
      localStorage.removeItem("localCart");
    } else {
      if (localCartData) {
        setLocalCart(localCartData);
      }
    }
  }, [customerID, itemAddEvent, itemDeleteEvent, itemMainCartEvent]);

  // Toggle search bar
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  // Trigger mobile menu
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu");
    offcanvasMobileMenu.classList.add("active");
  };

  // Handle logout with confirmation
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      text: "You will be redirected to the login page.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("restaurants-customerID");
        navigate("/login-register");
      }
    });
  };

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      {/* Search bar */}
      <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={handleClick}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>

      {/* Cart bag */}
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={() => navigate("/cart")}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cart.length
              ? cart.length
              : localCart.length
              ? localCart.length
              : 0}
          </span>
        </button>
      </div>

      {/* My Account */}
      <div className="same-style cart-wrap">
        {customerID ? (
          <button
            className="account-setting-active text-center"
            onClick={handleLogout}
          >
            <i className="pe-7s-user-female" /> <p>Logout</p>{" "}
           
          </button>
        ) : (
          <Link className="icon-cart text-center" to="/login-register">
            <i className="pe-7s-user-female" /> <p>Login</p>
          </Link>
        )}
      </div>

      {/* Responsive bar */}
      <div className="same-style cart-wrap d-block d-lg-none">
        <button className="icon-cart" onClick={handleClick}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cart.length
              ? cart.length
              : localCart.length
              ? localCart.length
              : 0}
          </span>
        </button>
        {/* Menu cart */}
        <MenuCart />
      </div>

      {/* Mobile off-canvas */}
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={triggerMobileMenu}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
  itemAddEvent: PropTypes.func,
  itemDeleteEvent: PropTypes.func,
  itemMainCartEvent: PropTypes.func,
};

export default IconGroup;
