import React from "react";
import { h2, useNavigate } from "react-router-dom";
import S_Choose from "./StatusBar/S_Choose";
import delivery from "./pngwing.com (2).png";
import pickup from "./pngwing.com (1).png";
import { useEffect } from "react";

function Pickup_Delivery() {
  const navigate = useNavigate();

  useEffect(()=>{
    localStorage.setItem('deliveryMode','')
  },[])

  const handleDeliveryMode = (path)=>{
    if (path == "address"){
      localStorage.setItem("deliveryMode", 'delivery')
    } 
    navigate(''+path)
  }

  return (
    <div className="mb-5">
      <S_Choose />
      <center>
        <h4 className="m-5 ">Choose Pickup or Delivery</h4>

        <div className="d-lg-flex justify-content-center align-items-center">
          <div className="border p-3">
            <h2
              onClick={() => handleDeliveryMode("choose-pickup-time")}
            >
              <div>
                <img style={{ width: "70px" }} src={pickup}></img>
              </div>

              <div
                style={{ background: "rgb(167, 73, 255)" }}
                className="btn px-5  text-white"
              >
                PICKUP
              </div>
            </h2>
          </div>
          <h4 className="mx-3">or</h4>
          <div className="border p-3">
            <h2
              onClick={() => handleDeliveryMode("address")}
            >
              <div>
                <img style={{ width: "80px" }} src={delivery}></img>
              </div>
              <div>
                <div
                  style={{ background: "rgb(167, 73, 255)" }}
                  className="text-white btn px-5 "
                >
                  DELIVERY
                </div>
              </div>
            </h2>
          </div>
        </div>
      </center>
    </div>
  );
}

export default Pickup_Delivery;
