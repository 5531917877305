import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import upiImg from "../../assets/images/UPI.png";
import cardImg from "../../assets/images/card.png";
import Loader from "../../components/Loaders/Loader";
import S_Payment from "./StatusBar/S_Payment";

function Payment() {
	const [paymentMode, setPaymentMode] = useState("");
	const navigate = useNavigate();
	const location = useLocation();
	const addressID = location?.state?.addressID || null;
	const collectionTime = location?.state?.collectionTime || null;
	const purchaseMode = addressID != null ? "delivery" : "pickup";

	//*** convert date
	const currentDate = new Date();

	const formattedDate = currentDate.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});

	//***authendication code***/
	const customerID = localStorage.getItem("restaurants-customerID");

	const [loading, setLoading] = useState(true);

	//* get cart data
	const [cart, setCart] = useState([]);
	useEffect(() => { 
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/cartdata`, {
				headers: { Authorization: customerID },
			})
			.then((res) => setCart(res.data))
			.catch((err) => console.log(err));
	}, []);

	//*get address data
	const [address, setAddress] = useState({});

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/getaddress/${addressID}`, {
				headers: { Authorization: customerID },
			})
			.then((res) => {
				setAddress(res.data);
				setLoading(false);
			})
			.catch((err) => console.log(err));
	}, []);

	const [customerName, setCustomerName] = useState('')

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/getCustomerName`, {
				headers: { Authorization: customerID },
			})
			.then((res) => {
				setCustomerName(res.data);
			})
			.catch((err) => console.log(err));
	}, []);

	console.log('customer name -> ',customerName);

	

	//* handle payment
	const handlepay = (e) => {
		e.preventDefault();
		let tipCost = Number(localStorage.getItem("tipCost"));
		
		console.log('tip -> ', tipCost);
		if (cart.length > 0) {
			axios
				.post(
					`${process.env.REACT_APP_FOS_CLIENT_API}/orderdetails`,
					{
						cart,
						paymentMode,
						customerID,
						addressID,
						purchaseMode,
						collectionTime,
						tipCost,
						customerName
					},
					{ headers: { Authorization: customerID } },
				)
				.then((res) => {
					localStorage.setItem('tipCost',0)
					// axios.delete(
					// 	`${process.env.REACT_APP_FOS_CLIENT_API}/deleteorderpending/${customerID}`,
					// );
				});
			axios.post(
				`${process.env.REACT_APP_FOS_CLIENT_API}/handleResetCart`,
				{},
				{
					headers: { Authorization: customerID },
				},
			);
			Swal.fire({
				icon: "success",
				title: "🥳 ORDER PLACED 🥳",
				text: "Continue Shopping To Extra Discounts",
				footer: '<h5>Check order status in "My Orders" </h5>',
			});
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Something went wrong!",
			});
		}
		navigate("/");
	};
	return (
		<form onSubmit={handlepay}>
			{loading ? (
				<Loader color="#000000" height="95px" />
			) : (
				<div>
					<S_Payment />
					{addressID ? (
						//~to show address details
						<div className="d-flex border pt-3 ps-3">
							<div>
								<div className="d-flex">
									<b className="me-2">{address.customername},</b>
									<p className="me-2">{address.phnum},</p>
								</div>
								<div className="d-flex">
									<p className="me-2">{address.address},</p>
									<p>{address.pincode}.</p>
									<div className="ms-3">
										<Link to={"/checkout/address"}>
											<FontAwesomeIcon
												className="me-4"
												style={{
													cursor: "pointer",
													color: "blue",
													fontSize: "1.6rem",
												}}
												icon={faRetweet}
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : (
						//~to show pickup details
						<div className="d-flex border pt-3 ps-3">
							<div>
								<h5>
									Pickup Date : <b>{formattedDate}</b>
								</h5>
								<h5>
									Pickup Time : <b>{collectionTime}</b>
								</h5>
							</div>
							<div className="ms-3">
								<Link to={"/checkout/choose-pickup-time"}>
									<FontAwesomeIcon
										className="me-4"
										style={{
											cursor: "pointer",
											color: "blue",
											fontSize: "1.6rem",
										}}
										icon={faRetweet}
									/>
								</Link>
							</div>
						</div>
					)}
				</div>
			)}

			{/* UPI payment */}
			<label className="row pb-2">
				<div className="row mt-3">
					<div className="col-2">
						<input
							style={{ height: "1rem" }}
							type="radio"
							onChange={(e) => setPaymentMode(e.target.value)}
							value="card"
							name="address"
							required
						/>
					</div>
					<div className="col-10">
						<h5>
							<b>GPay, Phonepy and all UPI's</b>
							<span>(All type of UPI available)</span>
						</h5>
					</div>
				</div>
				<div className="row">
					<div className="col-2"></div>
					<div className="col-6">
						<img style={{ width: "15rem" }} src={upiImg} alt="" />
					</div>
				</div>
			</label>

			{/* card payment */}
			<label className="row pb-2">
				<div className="row mt-3">
					<div className="col-2">
						<input
							style={{ height: "1rem" }}
							type="radio"
							onChange={(e) => setPaymentMode(e.target.value)}
							value="card"
							name="address"
							required
						/>
					</div>
					<div className="col-10">
						<h5>
							<b>Credit / Debit / ATM Card</b>
							<span>(All type of cards available)</span>
						</h5>
					</div>
				</div>
				<div className="row">
					<div className="col-2"></div>
					<div className="col-6">
						<img style={{ width: "15rem" }} src={cardImg} alt="" />
					</div>
				</div>
			</label>

			{/* COD payment */}
			<label className="row mt-3">
				<div className="col-2">
					<input
						style={{ height: "1rem" }}
						type="radio"
						onChange={(e) => setPaymentMode(e.target.value)}
						value="cod"
						name="address"
						required
					/>
				</div>
				<div className="col-10">
					<h5>
						<b>Cash On Delivery</b>
					</h5>
					<span>(use online payment to get extra rewards)</span>
				</div>
			</label>

			<center className="my-3">
				<button
					className="btn btn-primary me-3"
					style={{ background: "rgb(167, 73, 255)" }}
					onClick={() =>
						addressID
							? navigate(process.env.PUBLIC_URL + "/checkout/address")
							: navigate(
									process.env.PUBLIC_URL + "/checkout/choose-pickup-time",
							  )
					}
				>
					Back
				</button>
				<button
					style={{ background: "rgb(167, 73, 255)" }}
					className="btn btn-primary"
				>
					Proceed
				</button>
			</center>
		</form>
	);
}

export default Payment;
