import { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "./helpers/scroll-top";
import Home from "./pages/home/Home";
import Address from "./pages/other/Address";
import MyOrders from "./pages/other/MyOrders";
import NewAddress from "./pages/other/NewAddress";
import Payment from "./pages/other/Payment";
import Pickup_Delivery from "./pages/other/Pickup_Delivery";
import Pickup_Time from "./pages/other/Pickup_Time";
import AddPartner from "./pages/other/AddPartner";

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
	return (
		<Router>
			<ScrollToTop>
				<Suspense
					fallback={
						<div className="flone-preloader-wrapper">
							<div className="flone-preloader">
								<span></span>
								<span></span>
							</div>
						</div>
					}
				>
					<Routes>
						{/*~Homepages*/}

						<Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />

						{/* Other pages */}
						<Route
							path={process.env.PUBLIC_URL + "/contact"}
							element={<Contact />}
						/>
						<Route
							path={process.env.PUBLIC_URL + "/my-account"}
							element={<MyAccount />}
						/>
						<Route
							path={process.env.PUBLIC_URL + "/support"}
							element={<AddPartner/>}
						/>
						<Route
							path={process.env.PUBLIC_URL + "/login-register"}
							element={<LoginRegister />}
						/>
						<Route
							path={process.env.PUBLIC_URL + "/myorders"}
							element={<MyOrders />}
						/>
						<Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
						<Route
							path={process.env.PUBLIC_URL + "/newaddress"}
							element={<NewAddress />}
						/>
						<Route
							path={process.env.PUBLIC_URL + "/checkout"}
							element={<Checkout />}
						>
							<Route
								path={process.env.PUBLIC_URL + "address"}
								element={<Address />}
							></Route>
							<Route
								path={process.env.PUBLIC_URL + ""}
								element={<Pickup_Delivery />}
							></Route>
							<Route
								path={process.env.PUBLIC_URL + "choose-pickup-time"}
								element={<Pickup_Time />}
							></Route>
							<Route
								path={process.env.PUBLIC_URL + "newaddress"}
								element={<NewAddress />}
							></Route>
							<Route
								path={process.env.PUBLIC_URL + "payment"}
								element={<Payment />}
							></Route>
						</Route>

						<Route path="*" element={<NotFound />} />
					</Routes>
				</Suspense>
			</ScrollToTop>
		</Router>
	);
};

export default App;
