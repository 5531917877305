import axios from "axios";
import React, { Fragment } from "react";
import { useReducer } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/Loaders/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import { Popover } from "antd";

function HomeCart({
  itemAddEvent,
  handleItemDelete,
  checkoutButtonVisible,
  handleConfirmationRemoveStatus,
}) {
  const [localCart, setLocalCart] = useState([]);
  const [cart, setCart] = useState([]);
  let cartTotalPrice = 0;
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const taxAndCharge = 5;

  const deliveryMode = localStorage.getItem('deliveryMode')

  useEffect(()=>{
    if(deliveryMode=='delivery'){
      setDeliveryCharge(50)
    }
    else{
      setDeliveryCharge(0)
    }
  },[deliveryMode])

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  const [loading, setLoading] = useState(true);
  console.log(localCart, "localcart");
  //* delivery tip

  const [selectedTip, setSelectedTip] = useState(0);
  const [showTipOptions, setShowTipOptions] = useState(false);
  const [toppings, setToppings] = useState([]);

  console.log("topppingfd", toppings);

  const handleTipSelection = (tip) => {
    setSelectedTip(tip);
    localStorage.setItem("tipCost", tip);
  };

  const clearSelectedTip = () => {
    setSelectedTip(0);
    setShowTipOptions(false);
  };

  //***authendication code***/
  const customerID = localStorage.getItem("restaurants-customerID");

  //* get db cart data

  useEffect(() => {
    if (customerID) {
      axios
        .get(`${process.env.REACT_APP_FOS_CLIENT_API}/cartdata`, {
          headers: {
            Authorization: customerID,
          },
        })
        .then((res) => {
          setCart(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setCart([]);
    }
  }, [reducerValue, itemAddEvent, customerID]);

  console.log("cart", cart);

  // //get toppings list
  // const toppingsId = [7, 8, 9];
  // useEffect(() => {
  //   const fetchToppings = async () => {
  //     try {
  //       if (!toppingsId || toppingsId.length === 0) {
  //         setToppings([]);
  //         return;
  //       }

  //       const response = await axios.get(
  //         `${process.env.REACT_APP_FOS_CLIENT_API}/getdesiredtoppinglist`,
  //         {
  //           params: { toppingsId: toppingsId.join(",") },
  //         }
  //       );
  //       setToppings(response.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   fetchToppings();
  // }, []);

  //* delete cart data

  const handleDelete = (id) => {
    handleItemDelete();
    axios
      .delete(`${process.env.REACT_APP_FOS_CLIENT_API}/deletecartitem/${id}`, {
        headers: { Authorization: customerID },
      })
      .then((res) => handleConfirmationRemoveStatus())
      .catch((err) => console.log(err));
    forceUpdate();
  };
  console.log("top",toppings);

  //* increment

  function handleIncrement(itemId) {
    console.log("increment triggered");
    const clone = [...cart];
    const itemIndex = clone.findIndex((item) => item.cartid === itemId);
    if (clone[itemIndex].count < 5) {
      axios
        .put(
          `${process.env.REACT_APP_FOS_CLIENT_API}/cartincrement/`,
          { itemId },
          {
            headers: { Authorization: customerID },
          }
        )
        .then((res) => res)
        .catch((err) => console.log(err));
      forceUpdate();
    }
  }

  //* decrement

  function handleDecrement(itemId) {
    const clone = [...cart];
    const itemIndex = clone.findIndex((item) => item.cartid === itemId);
    if (clone[itemIndex].count > 1) {
      axios
        .put(
          `${process.env.REACT_APP_FOS_CLIENT_API}/cartdecrement/`,
          { itemId },
          {
            headers: { Authorization: customerID },
          }
        )
        .then((res) => res)
        .catch((err) => console.log(err));
      forceUpdate();
    }
  }

  //~Local Cart

  //* get local cart data

  useEffect(() => {
    const LocalcartInfo = JSON.parse(localStorage.getItem("localCart"));
    if (customerID != null) {
      localStorage.removeItem("localCart");
    } else {
      if (LocalcartInfo) {
        setLocalCart(LocalcartInfo);
      }
      setLoading(false);
    }
  }, [customerID, itemAddEvent]);

  // let localCartInfo = localCart[0].toppingNames;
  // console.log('local cart -> ', localCartInfo.split(','));

  //*handle local cart decrement
  const handleLocalCartDecrement = (itemId) => {
    const clone = [...localCart];
    const itemIndex = clone.findIndex((item) => item.cartid === itemId);

    if (clone[itemIndex].count > 1) {
      clone[itemIndex].count--;
      clone[itemIndex].subtotal =
        clone[itemIndex].count * clone[itemIndex].semiTotal;
      setLocalCart(clone);
      localStorage.setItem("localCart", JSON.stringify(clone));
    }
  };

  //*handle local cart increment
  const handleLocalCartIncrement = (itemId) => {
    const clone = [...localCart];
    const itemIndex = clone.findIndex((item) => item.cartid === itemId);
    if (clone[itemIndex].count < 5) {
      clone[itemIndex].count++;
      clone[itemIndex].subtotal =
        clone[itemIndex].count * clone[itemIndex].semiTotal;
      setLocalCart(clone);
      localStorage.setItem("localCart", JSON.stringify(clone));
    }
  };

  //*handle local cart delete

  const handleLocalCartDelete = (itemId) => {
    handleItemDelete();
    const updatedLocalCart = localCart.filter((item) => item.cartid !== itemId);
    setLocalCart(updatedLocalCart);
    handleConfirmationRemoveStatus();
    localStorage.setItem("localCart", JSON.stringify(updatedLocalCart));
  };

  //* showCancellationPolicy

  function showCancellationPolicy() {
    Swal.fire(
      "<h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</h4>"
    );
  }

  return loading ? (
    <Loader color="#000000" height="300px" />
  ) : (
    <div>
      <h5 className="ps-2">Your Items : {cart.length || localCart.length}</h5>
      {(cart && cart.length > 0) || (localCart && localCart.length > 0) ? (
        <Fragment>
          <div
            className="shopping-cart-content"
            style={{
              maxHeight: "380px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {customerID != null
              ? cart.map((item, index) => {
                  cartTotalPrice += item.subtotal;
                  return (
                    <div key={index} className="row p-1">
                      <div className="col-3">
                        <img
                          src={
                            `${process.env.REACT_APP_FOS_CLIENT_API}/images/` +
                            item.image
                          }
                          style={{
                            borderRadius: "50%",
                            height: "4rem",
                            width: "4rem",
                          }}
                          alt="img"
                        />
                      </div>
                      <div className="col-6">
                        <h5>
                          {item.menuitem}
                          {item.base != null ? (
                            <span>({item.base.baseName})</span>
                          ) : (
                            ""
                          )}
                        </h5>
                        <div className="d-flex gap-2 pb-2">
                          <button
                            className="border py-0"
                            onClick={() => handleDecrement(item.cartid)}
                          >
                            -
                          </button>
                          <button sty style={{ border: "none" }}>
                            {item.count}
                          </button>
                          <button
                            className="border py-0"
                            onClick={() => handleIncrement(item.cartid)}
                          >
                            +
                          </button>
                        </div>
                        <h6>
                          Total: ${" "}
                          {Number.isInteger(item.subtotal)
                            ? item.subtotal
                            : item.subtotal.toFixed(1)}
                        </h6>
                        {item.toppings && item.toppings.length > 0 && (
                          <Popover
                            className="p-1 px-3 border"
                            placement="right"
                            title="Toppings"
                            content={
                              <div>
                                {item.toppings.map((m) => (
                                  <p key={m.toppingId}>
                                    {m.toppingName}($ {m.toppingPrice})
                                  </p>
                                ))}
                              </div>
                            }
                          >
                            {item.toppings[0].type} {">"}
                          </Popover>
                        )}
                      </div>

                      <div className="col-2 ">
                        <p onClick={() => handleDelete(item.cartid)}>
                          <i
                            className="fa fa-times-circle fs-5"
                            style={{ color: "#a749ff", cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  );
                })
              : localCart.map((item, index) => {
                  cartTotalPrice += parseFloat(item.subtotal); // Ensure subtotal is treated as a float
                  const subtotalInt = parseInt(item.subtotal); // Parse subtotal to integer if needed

                  return (
                    <div key={index} className="row p-1 mt-3">
                      <div className="col-3">
                        <img
                          src={`${process.env.REACT_APP_FOS_CLIENT_API}/images/${item.image}`}
                          style={{
                            borderRadius: "50%",
                            height: "4rem",
                            width: "4rem",
                          }}
                          alt="img"
                        />
                      </div>
                      <div className="col-6">
                        <h4>{item.menuname}</h4>
                        <div className="d-flex gap-2 pb-2">
                          <button
                            className="border py-0"
                            onClick={() =>
                              handleLocalCartDecrement(item.cartid)
                            }
                          >
                            -
                          </button>
                          <button style={{ border: "none" }}>
                            {item.count}
                          </button>
                          <button
                            className="border py-0"
                            onClick={() =>
                              handleLocalCartIncrement(item.cartid)
                            }
                          >
                            <span>+</span>
                          </button>
                        </div>
                        <h6>
                          Total:{" "}
                          <strong>
                            ${" "}
                            {Number.isInteger(subtotalInt)
                              ? subtotalInt
                              : item.subtotal}
                          </strong>
                        </h6>
                        {/* ==============================================POPHOVER================================================================== */}
                        {item.toppings && item.toppings.length > 0 && (
                          <Popover
                            className="p-1 px-3 border"
                            placement="right"
                            title={item.toppings[0].type}
                            content={
                              <div>
                                {item.toppings.map((m) => (
                                  <p key={m.toppingId}>
                                    {m.toppingName}($ {m.toppingPrice})
                                  </p>
                                ))}
                              </div>
                            }
                          >
                            {item.toppings[0].type} {">"}
                          </Popover>
                        )}
                      </div>
                      <div className="col-2">
                        <p onClick={() => handleLocalCartDelete(item.cartid)}>
                          <i
                            className="fa fa-times-circle fs-5"
                            style={{ color: "#a749ff", cursor: "pointer" }}
                          />
                        </p>
                      </div>
                    </div>
                  );
                })}
          </div>
          <hr />
          {checkoutButtonVisible ? (
            <div className="d-flex justify-content-between">
              <h5 className="ps-3">Item Total </h5>
              <h5 className="shop-total">$ {cartTotalPrice.toFixed(2)}</h5>
            </div>
          ) : (
            <div className="shopping-cart-total py-3 text-end px-4">
              <div className="d-flex justify-content-between">
                <h5 className="ps-3">Item Total </h5>
                <h5 className="shop-total">$ {cartTotalPrice.toFixed(2)}</h5>
              </div>

              {deliveryMode == "delivery" ? (
                
                <div>
                  <div className="d-flex justify-content-between">
                    <h5 className="ps-3">Delivery partner fee </h5>
                    <h5 className="shop-total">
                      $ {deliveryCharge.toFixed(2)}
                    </h5>
                  </div>

                  <div className="d-flex justify-content-between">
                    <h5 className="ps-3">Delivery Tip </h5>
                    {showTipOptions ? (
                      <div className="d-flex gap-2">
                        <button
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor:
                              selectedTip === 10 ? "#a749ff" : "white",
                            color: selectedTip === 10 ? "white" : "black",
                          }}
                          onClick={() => handleTipSelection(10)}
                        >
                          $ 10
                        </button>
                        <button
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor:
                              selectedTip === 20 ? "#a749ff" : "white",
                            color: selectedTip === 20 ? "white" : "black",
                          }}
                          onClick={() => handleTipSelection(20)}
                        >
                          $ 20
                        </button>
                        <button
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor:
                              selectedTip === 50 ? "#a749ff" : "white",
                            color: selectedTip === 50 ? "white" : "black",
                          }}
                          onClick={() => handleTipSelection(50)}
                        >
                          $ 50
                        </button>
                        <button
                          style={{
                            border: "1px solid grey",
                            borderRadius: "5px",
                            cursor: "pointer",
                            background: "grey",
                            color: "white",
                          }}
                          onClick={clearSelectedTip}
                        >
                          X
                        </button>
                      </div>
                    ) : (
                      <button
                        style={{
                          border: "1px solid grey",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowTipOptions(true)}
                      >
                        Add Tip
                      </button>
                    )}
                  </div>
                </div>
                
              ) : (
                ""
              )}

              <div className="pt-2 d-flex justify-content-between">
                <h5 className="ps-3">Tax and Charges ({taxAndCharge} %)</h5>
                <h5 className="shop-total">
                  $ {((cartTotalPrice / 100) * taxAndCharge).toFixed(2)}
                </h5>
              </div>
              <div className="d-flex justify-content-between">
                <h5
                  onClick={showCancellationPolicy}
                  style={{ cursor: "pointer" }}
                  className="ps-3"
                >
                  {" "}
                  Cancellation Policy{" "}
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </h5>

                <h5></h5>
              </div>

              <hr />

              <div className=" d-flex justify-content-between">
                <h4 className="ps-3">
                  <b>To Pay</b>{" "}
                </h4>
                <h4 className="shop-total">
                  <b>
                    ${" "}
                    {(
                      cartTotalPrice +
                      deliveryCharge +
                      selectedTip +
                      (cartTotalPrice / 100) * taxAndCharge
                    ).toFixed(2)}
                  </b>
                </h4>
              </div>
            </div>
          )}
          {checkoutButtonVisible ? (
            <div className="shopping-cart-btn btn-hover text-center">
              <Link
                className="default-btn"
                to={process.env.PUBLIC_URL + "/checkout"}
              >
                checkout
              </Link>
            </div>
          ) : (
            ""
          )}
        </Fragment>
      ) : (
        <p className="text-center py-3">No items found in cart</p>
      )}
    </div>
  );
}

export default HomeCart;
