import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import S_Address from './StatusBar/S_Address';
import { Link, useNavigate } from 'react-router-dom';
import S_Pickup from './StatusBar/S_Pickup';

const Pickup_Time = () => {
  const [selectedTime, setSelectedTime] = useState(''); // Store the selected time as a string 'hh:mm'
  const currentTime = new Date();
  currentTime.setMinutes(Math.ceil(currentTime.getMinutes() / 10) * 10);
  const availableHours = 5;

  const availableTimes = [];
  for (let i = 0; i < availableHours * 60; i += 10) {
    const time = new Date(currentTime);
    time.setMinutes(currentTime.getMinutes() + i);
    availableTimes.push(time);
  }

  const navigate = useNavigate();

  const handleSubmit = () => {

    navigate("/checkout/payment", {
			state: { collectionTime: selectedTime }
		});
  };

  console.log(selectedTime);

  return (
    <Container>
      <S_Pickup/>
      <Row className="mt-5">
        <Col>
          <h4>Current Date</h4>
          <h4><b>{currentTime.toDateString()}</b></h4>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h4>Choose Pickup Time</h4>
          <Form>
            <Form.Group controlId="pickupTime">
              <Form.Control
                as="select"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
              >
                <option value="">Select Pickup Time</option>
                {availableTimes.map((time) => (
                  <option key={time.toISOString()} value={time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}>
                    {time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <center className="mt-5">
          <Link to={'/checkout'}>
            <Button
            style={{ background: "rgb(167, 73, 255)" }}
            className="btn btn-primary me-3"
            >
              Back
            </Button>
          </Link>
          <Button
            style={{ background: "rgb(167, 73, 255)" }}
            className="btn btn-primary"
            disabled={!selectedTime}
            onClick={handleSubmit}
          >
            Proceed
          </Button>
      </center>
    </Container>
  );
};

export default Pickup_Time;
