import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { useReducer } from "react";
import AddressUpdate from "./AddressUpdate";
import Swal from "sweetalert2";
import NewAddress from "./NewAddress";
import Loader from "../../components/Loaders/Loader";
import S_Address from "./StatusBar/S_Address";

function Address({ accountSection }) {
	const [id, setId] = useState(0);

	const [loading, setLoading] = useState(true);

	const [selectedId, setSelectedId] = useState(0);

	const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

	const navigate = useNavigate();

	const [addressUpdateEvent, setAddressUpdateEvent] = useState(false);

	//***authendication code***/
	const customerID = localStorage.getItem("restaurants-customerID");

	//* deleting existing address

	const handleDelete = (id) => {
		setId(id);

		//~ get confirmation to delete or not

		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger",
			},
			buttonsStyling: false,
		});

		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Yes, delete it!",
				cancelButtonText: "No, cancel!",
				reverseButtons: true,
			})
			.then((result) => {
				if (result.isConfirmed) {
					//~delete address in db
					axios
						.delete(
							`${process.env.REACT_APP_FOS_CLIENT_API}/deleteaddress/${id}`,
							{ headers: { Authorization: customerID } },
						)
						.then((res) => {
							swalWithBootstrapButtons.fire(
								"Deleted!",
								"Your address has been deleted.",
								"success",
							);
							forceUpdate();
						})
						.catch((err) => console.log(err));
				}
			});
	};

	//* Get user address details

	const [address, setAddress] = useState([]);

	const [newAddress, setNewAddress] = useState(false);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/customerexistingaddress`, {
				headers: { Authorization: customerID },
			})
			.then((res) => {
				setAddress(res.data);
				setLoading(false);
			})
			.catch((err) => console.log(err));
	}, [reducerValue, addressUpdateEvent]);

	//* Handle Address Update Event

	const handleAddressUpdateEvent = () => {
		setAddressUpdateEvent(false);
	};

	//* cart data
	const [cart, setCart] = useState([]);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_FOS_CLIENT_API}/cartdata`, {
				headers: { Authorization: customerID },
			})
			.then((res) => setCart(res.data))
			.catch((err) => console.log(err));
	}, []);

	//* Handle Proceed

	const handleProceed = (e) => {
		e.preventDefault();
		navigate("/checkout/payment", {
			state: { addressID: id },
		});
	};

	return loading ? (
    <Loader color="#000000" height="300px" />
  ) : (
    <div>
      <S_Address />
      {addressUpdateEvent ? (
        <AddressUpdate
          addId={selectedId}
          handleAddressUpdateEvent={handleAddressUpdateEvent}
        />
      ) : //~ Existing address
      address.length > 0 && !newAddress ? (
        <div>
          <h5 className="text-primary mt-3 mb-2">Existing Address</h5>
          <form onSubmit={handleProceed} action="">
            <div
              className="mb-3"
              style={{
                maxHeight: "16rem",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {address.map((m, index) => (
                <label className="row mb-4">
                  <div className="col-1" style={{ cursor: "pointer" }}>
                    <input
                      onChange={(e) => setId(e.target.value)}
                      className="me-2"
                      type="radio"
                      name="address"
                      value={m.addressID}
                      required
                      style={{
                        height: "1rem",
                      }}
                    />
                  </div>
                  <div className="col-7">
                    <b className="me-1">{m.customername},</b>
                    <div className="row">
                      <p className="col-lg-6">
                        <span className="me-2">{m.phnum}, </span>
                      </p>
                    </div>
                    <p className="me-1">{m.address}</p>
                    <p style={{ marginTop: "-13px" }}>{m.pincode}</p>
                  </div>
                  <div className="col-4">
                    <FontAwesomeIcon
                      className="me-4"
                      onClick={() => {
                        setSelectedId(m.addressID);
                        setAddressUpdateEvent(true);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontSize: "1.3rem",
                      }}
                      icon={faPenToSquare}
                    />
                    <FontAwesomeIcon
                      className="me-4"
                      onClick={() => handleDelete(m.addressID)}
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "1.3rem",
                      }}
                      icon={faTrashCan}
                    />
                  </div>
                </label>
              ))}
            </div>
            <Link
              style={{ color: "rgb(167, 73, 255)" }}
              onClick={() => setNewAddress(true)}
            >
              + Add New Address
            </Link>
            {accountSection ? (
              ""
            ) : (
              <div>
                <center>
                  <Link
                    onClick={() => localStorage.setItem("deliveryMode", "")}
                    to={"/checkout"}
                  >
                    <button
                      style={{ background: "rgb(167, 73, 255)" }}
                      className="my-3 me-3 btn btn-primary"
                    >
                      BACK
                    </button>
                  </Link>

                  <button
                    type="submit"
                    style={{ background: "rgb(167, 73, 255)" }}
                    className="my-3 btn btn-primary"
                  >
                    Proceed
                  </button>
                </center>
              </div>
            )}
          </form>
        </div>
      ) : (
        //~ New address
        <NewAddress forceUpdate={forceUpdate} setNewAddress={setNewAddress} />
      )}
    </div>
  );
}

export default Address;
